import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { connectRefinementList, connectPagination } from 'react-instantsearch-core';
import { Select } from 'antd';

import { BatchAlgoliaPickerContext } from '../context';
import { AppliedFilters, Filter } from '../interfaces';
import { breakpoints, colours } from '../tokens';

import { ReactComponent as FilterIcon } from './svg-icons/filter.svg';
import { ReactComponent as CloseIcon } from './svg-icons/close-edge.svg';

const { Option } = Select;

const VirtualRefinement = connectRefinementList(() => null);

type FilterControlProps = {
  filter: Filter;
  refine: (page: number) => void;
};

const FilterControl: React.FC<FilterControlProps> = ({ filter, refine: refinePage }) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>();
  const { currentFilters, setCurrentFilters } = useContext(BatchAlgoliaPickerContext);

  useEffect(() => {
    if (!selectedValue) return;

    const isFilteredByKey = currentFilters[selectedValue];

    const isFilteredByValue = filter.controlledAttributes
      .map((control) => control.attributeName)
      .some((attributeName) => currentFilters[attributeName] === selectedValue);

    if (!isFilteredByKey && !isFilteredByValue) {
      setSelectedValue(undefined);
    }
  }, [currentFilters, selectedValue, filter.controlledAttributes]);

  return (
    <ControlContainer>
      {filter.controlledAttributes
        .filter(({ attributeName }) => attributeName in currentFilters)
        .map(({ attributeName }) => {
          return (
            <VirtualRefinement
              key={`virtual-refinement-${attributeName}`}
              attribute={attributeName}
              defaultRefinement={[currentFilters[attributeName]]}
            />
          );
        })}

      <Select
        showSearch
        filterOption={(inputValue, { props }) => {
          const optionValue = props.value;
          const option = optionValue && filter.options.find((o) => o.value === optionValue);

          const label = option && option.label.toLowerCase();
          const searchTerm = inputValue.toLowerCase();

          return label ? label.includes(searchTerm) : false;
        }}
        value={selectedValue}
        placeholder={filter.label}
        suffixIcon={
          selectedValue ? (
            <StyledCloseIcon
              onClick={() => {
                const defaultFilters = filter.controlledAttributes.reduce(
                  (acc: AppliedFilters, attribute) => ({
                    ...acc,
                    [attribute.attributeName]: attribute.defaultValue
                  }),
                  {}
                );
                setSelectedValue(undefined);
                setCurrentFilters(defaultFilters);
              }}
            />
          ) : (
            <StyledFilterIcon height="16px" width="16px" />
          )
        }
        onChange={(value: string) => {
          setSelectedValue(value);
          filter.onChange(setCurrentFilters, value);
          refinePage(1);
        }}
      >
        {filter.options.map((option) => (
          <Option key={`select-option-${option.label}-${option.value}`} value={option.value}>
            <StyledOptionText>{option.label}</StyledOptionText>
          </Option>
        ))}
      </Select>
    </ControlContainer>
  );
};

const ControlContainer = styled.div`
  display: flex;

  > * {
    margin: 6px 0 0;
    @media (min-width: ${breakpoints.md}) {
      margin: 0 6px;
    }
  }

  .ant-select-selection {
    border-color: ${colours.darkGrey50};
  }

  .ant-select-selection:hover {
    border-color: #6b9fff;
  }

  .ant-select {
    width: 100%;

    .ant-select-selection__placeholder {
      color: ${colours.darkGrey700};
    }
    @media (min-width: ${breakpoints.md}) {
      width: 200px;
    }
  }
`;

const StyledOptionText = styled.span`
  color: rgb(74, 93, 133);
`;

const StyledFilterIcon = styled(FilterIcon)`
  margin-top: -2px;

  path {
    fill: ${colours.darkGrey700};
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  margin-top: -3px;
  margin-right: -6px;
`;

export const ConnectedFilterControl = connectPagination(FilterControl);
