import React from 'react';
import { SearchClient } from 'algoliasearch';
import { Column, AlgoliaEntry, AppliedFilters, Filter, ViewMode } from '../interfaces';

export interface IBatchAlgoliaPickerContext<T> {
  /**
   * The name of the index that the entire batch-picker experience is pointed towards.
   */
  algoliaIndexName: string;

  /**
   * Columns is an array of Column which tells us:
   * - The attribute name to take from the Aloglia index
   * - The display name for the column header
   * - Provide a custom render method incase the consumer wants to modify the render. (i.e. convert an array into a string)
   */
  columns: Array<Column<T>>;

  /**
   * List of drop-down filters for the table.
   */
  filters: Array<Filter>;

  /**
   * The current filters that is applied to the Algolia Search index.
   * Filtering only supports: strings, booleans and arrays.
   * Numbers, dates, tags are not supported with the filterKey:filterValue syntax.
   * https://www.algolia.com/doc/guides/managing-results/refine-results/filtering/
   *
   * See ~/ui/SummaryHeader.tsx to see implementation
   */
  currentFilters: AppliedFilters;

  /**
   * Control which view of the table is active.
   * SELECT_RECORDS = View that lets the user add/remove selections with live filters
   * VIEW_SELECTED_RECORDS = Filters are removed and the filter is to show all the currently selected records
   */
  currentViewMode: ViewMode;

  /**
   * Set filters that always need to be applied.
   * These filters remain applied even after filters are "cleared"
   */
  persistentFilters: AppliedFilters;

  /**
   * The name of the entity that this Algolia index represents. Throughout the application we use this to show labels/buttons
   * For example:
   * entitySingularName: 'user'
   * entityPluralName: 'users'
   *
   * Defaults to 'record' (singlular) and 'records' (plural)
   */
  entitySingularName: string;
  entityPluralName: string;

  /**
   * When the user clicks on "Select All", this switches to true
   * When the user changes filters, this switches to false because it is now a new dataset
   */
  hasSelectedAll: boolean;

  /**
   * isSelectable is for us to ask the consumer if the row is selectable according to their own logic.
   */
  isSelectable: (row: T) => boolean;

  /**
   * isSelectedExternal is for us to ask the consumer if the row is already selected according to their own dataset.
   */
  isSelectedExternal: (row: T) => boolean;

  /**
   * isSelectedInternal is for us to check if the row is already selected in our own internal state which hasn't been sent up to the consumer yet.
   */
  isSelectedInternal: (row: T) => boolean;

  /**
   * Callback provided by the consumer of the batch-picker on what to do when the user clicks cancel
   */
  onCancel?: () => void;

  /**
   * On rowSelect is used to add/remove from internal state dependening on whether it is already in the current internal or external selection state.
   */
  onRowSelect: (row: T) => void;

  /**
   * Callback provided by the consumer of the batch-picker on what to do when the user clicks submit/save
   */
  onSubmit: (entries: Array<T>) => void;
  onSubmitText: string;

  /**
   * Memoized reference to the Algolia search client so all children components can connect to it.
   */
  searchClient?: SearchClient;

  /**
   * Selected rows are the rows from the Algolia index that is currently selected and ready to be sent up to the consumer when the main "Save" button is clicked.
   */
  selectedRows: Array<T>;

  /**
   * Partial update or clear the filters that are applied to the Algolia Search index.
   * This is only our internal state of the filters. The actual Algolia index is managed internally by one of the components.
   * We are keeping a copy of this in our Context so other components can read from it.
   */
  setCurrentFilters: (filters?: AppliedFilters) => void;

  /**
   * Update the current view mode to toggle between the two allowed views.
   */
  setCurrentViewMode: (viewMode: ViewMode) => void;

  /**
   * setHasSelectedAll is a state mutator to toggle whether or not the user can see the "Select All" link.
   */
  setHasSelectedAll: React.Dispatch<React.SetStateAction<boolean>>;

  /**
   * setSelectedRows is a state mutator to destructively update the current selected data-set. All rows not included in this mutation are unselected.
   */
  setSelectedRows: React.Dispatch<React.SetStateAction<Array<T>>>;

  /**
   *setTotalRecordsCount is a state mutator to keep track of how records are there in the current filtered data-set.
   */
  setTotalRecordsCount: React.Dispatch<React.SetStateAction<number>>;

  /**
   * totalRecordsCount is how many records the current filter combination has yielded according to Algolia.
   */
  totalRecordsCount: number;

  /**
   * Number of records to show per page in the table views
   */
  recordsPerPage: number;
}

export const BatchAlgoliaPickerContext = React.createContext<IBatchAlgoliaPickerContext<AlgoliaEntry>>({
  algoliaIndexName: '',
  columns: [],
  currentFilters: {},
  currentViewMode: ViewMode.SELECT_RECORDS,
  persistentFilters: {},
  entityPluralName: 'records',
  entitySingularName: 'record',
  filters: [],
  hasSelectedAll: false,
  isSelectable: () => true,
  isSelectedExternal: () => false,
  isSelectedInternal: () => false,
  onRowSelect: () => null,
  onSubmit: () => null,
  onSubmitText: 'Add',
  recordsPerPage: 10,
  searchClient: undefined,
  selectedRows: [],
  setCurrentFilters: () => null,
  setCurrentViewMode: () => null,
  setHasSelectedAll: () => null,
  setSelectedRows: () => null,
  setTotalRecordsCount: () => null,
  totalRecordsCount: 0
});
