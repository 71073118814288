import React, { useContext } from 'react';
import { InstantSearch } from 'react-instantsearch-dom';
import { Refinement } from 'react-instantsearch-core';
import styled from 'styled-components';

import { breakpoints, colours } from '../tokens';
import { BatchAlgoliaPickerContext } from '../context';
import { ConnectedSearchResults } from './ConnectedSearchResults';
import { ConnectedFilterControl } from './ConnectedFilterControl';
import { ConnectedSearchBox } from './ConnectedSearchBox';
import { ConnectedClearResults } from './ConnectedClearResults';
import { ConnectedPagination } from './ConnectedPagination';
import { ConnectedPersistentFilters } from './ConnectedPersistentFilters';

const ContainerAlgolia: React.FC = () => {
  const { searchClient, algoliaIndexName, filters } = useContext(BatchAlgoliaPickerContext);

  if (!searchClient) return null;

  return (
    <InstantSearch indexName={algoliaIndexName} searchClient={searchClient}>
      <SearchActions>
        <FilterControls>
          <ConnectedSearchBox />
          {filters.map((filter) => (
            <ConnectedFilterControl key={filter.label} filter={filter} />
          ))}
          <ConnectedPersistentFilters />
          <ClearContainer>
            <ConnectedClearResults
              clearsQuery
              transformItems={(items: Refinement[]) => {
                // Transform is to filter out the "facet" filters, so that we only remove the "query" string refinement.
                // "facets" are removed/handled via our "VirtualRefinement" components inside FilterControl (via our state)
                return items.filter(({ id }) => id === 'query');
              }}
            />
          </ClearContainer>
        </FilterControls>
      </SearchActions>
      <ConnectedSearchResults />
      <ConnectedPagination />
    </InstantSearch>
  );
};

export { ContainerAlgolia };

const SearchActions = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
  }

  padding: 18px;
  border-bottom: 1px solid lightgrey; // TODO: Fix colour
`;

const FilterControls = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
    width: auto;
  }
`;

const ClearContainer = styled.div`
  margin-top: 6px;
  @media (min-width: ${breakpoints.md}) {
    margin: 0 0 0 6px;
  }
  button {
    border-color: ${colours.darkGrey100};
  }
`;
