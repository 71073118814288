import { useContext } from 'react';
import {
  connectHits,
  connectStateResults,
  HitsProvided,
  StateResultsProvided,
  BasicDoc
} from 'react-instantsearch-core';
import { Checkbox } from 'antd';
import styled from 'styled-components';

import { BatchAlgoliaPickerContext } from '../context';
import { SelectAllCheckbox } from './SelectAllCheckbox';
import { AlgoliaEntry } from '../interfaces';
import { safeRender } from '../utils';
import { Table, HeaderRow, Row, Column } from './common/Table';

type InfiniteScrollProps = Pick<HitsProvided<AlgoliaEntry & BasicDoc>, 'hits'>;
type SearchResultsProps = InfiniteScrollProps & StateResultsProvided;

const SearchResults: React.FC<SearchResultsProps> = ({ hits, error }) => {
  const { onRowSelect, isSelectable, isSelectedExternal, isSelectedInternal, entityPluralName, columns } = useContext(
    BatchAlgoliaPickerContext
  );

  if (!hits || error) {
    return null;
  }

  return (
    <Container>
      <Table>
        {/* Cols & colgroups to easily set style/width for columns & for a11y */}
        <colgroup className="colgroup-checkbox">
          <Column className="col-checkbox" />
        </colgroup>

        <colgroup className="colgroup-content" span={columns.length}>
          {columns.map(({ algoliaAttributeName, columnWidth }) => (
            <Column key={algoliaAttributeName} columnWidth={columnWidth} className="col-content" />
          ))}
        </colgroup>

        <HeaderRow>
          <th scope="col">
            <SelectAllCheckbox />
          </th>

          {columns.map(({ algoliaAttributeName, displayName }) => (
            <th key={algoliaAttributeName} scope="col">
              {displayName}
            </th>
          ))}
        </HeaderRow>

        {hits.map((hit) => {
          const isChecked = isSelectedExternal(hit) || isSelectedInternal(hit);
          const isDisabled = !isSelectable(hit);

          return (
            <Row
              key={hit.objectID}
              onClick={() => !isDisabled && onRowSelect(hit)}
              checked={isChecked}
              disabled={isDisabled}
            >
              <td>
                {/* TODO: Slight top/bottom margin misclick here */}
                <Checkbox checked={isChecked} disabled={isDisabled} />
              </td>

              {columns.map(({ algoliaAttributeName, render }) => (
                <td key={algoliaAttributeName} className="cell-content">
                  {render ? render(hit[algoliaAttributeName], hit) : safeRender(hit[algoliaAttributeName])}
                </td>
              ))}
            </Row>
          );
        })}
      </Table>

      {hits.length === 0 && <NoRecordsContainer>There are no {entityPluralName} found</NoRecordsContainer>}
    </Container>
  );
};

const Results = connectStateResults(SearchResults);
export const ConnectedSearchResults = connectHits(({ hits }) => <Results hits={hits} />);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  overflow: auto;
`;

const NoRecordsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  font-size: 18px;
  margin-top: 24px;
  font-weight: 300;
`;
