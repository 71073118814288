export const breakpoints = {
  xxs: '0320px', // xxs
  xs: '0480px', // xs
  sm: '0576px', // sm
  md: '0768px', // md
  lg: '0992px', // lg
  xl: '1200px', // xl
  xxl: '1500px' // xxl - 1440 + gutter*2
  // nothing > xxl
};
