import { AlgoliaEntry } from '../interfaces';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Checkbox, Pagination } from 'antd';
import { chunk } from 'lodash';

import { BatchAlgoliaPickerContext } from '../context';
import { safeRender } from '../utils';
import { Table, HeaderRow, Row, Column, PaginationSection } from './common';

const ContainerReview: React.FC = () => {
  const { selectedRows, columns, setSelectedRows, entityPluralName, setHasSelectedAll, recordsPerPage } = useContext(
    BatchAlgoliaPickerContext
  );

  const [chunkedRows, setChunkedRows] = useState<Array<AlgoliaEntry>>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    const chunks = chunk(selectedRows, recordsPerPage);
    const currentChunk = chunks[currentPage - 1];
    const chunkedRows = currentChunk || [];

    setChunkedRows(chunkedRows);
  }, [currentPage, selectedRows, recordsPerPage]);

  const deselect = (rowToRemove?: AlgoliaEntry) => {
    if (rowToRemove) {
      // Select a specific row:
      setSelectedRows((rows) => rows.filter((row) => row.objectID !== rowToRemove.objectID));
    } else {
      // Deselect all:
      setSelectedRows([]);
    }

    setHasSelectedAll(false);
  };

  const rangeStart = Math.min((currentPage - 1) * recordsPerPage + 1, selectedRows.length);
  const rangeEnd = Math.min(currentPage * recordsPerPage, selectedRows.length);

  return (
    <Container>
      <ResultsHeader>Viewing selected {entityPluralName} only</ResultsHeader>
      <TableContainer>
        <Table>
          {/* Cols & colgroups to easily set style/width for columns & for a11y */}
          <colgroup className="colgroup-checkbox">
            <Column className="col-checkbox" />
          </colgroup>

          <colgroup className="colgroup-content" span={columns.length}>
            {columns.map(({ algoliaAttributeName, columnWidth }) => (
              <Column key={algoliaAttributeName} columnWidth={columnWidth} className="col-content" />
            ))}
          </colgroup>

          <HeaderRow>
            <th scope="col">
              <Checkbox
                onChange={() => deselect()}
                disabled={selectedRows.length === 0}
                checked={selectedRows.length > 0}
              />
            </th>

            {columns.map(({ algoliaAttributeName, displayName }) => (
              <th key={algoliaAttributeName} scope="col">
                {displayName}
              </th>
            ))}
          </HeaderRow>

          {chunkedRows.map((row) => {
            return (
              <Row key={row.objectID} onClick={() => deselect(row)} checked={true}>
                <td>
                  {/* TODO: Slight top/bottom margin misclick here */}
                  <Checkbox onChange={() => deselect(row)} checked={true} />
                </td>

                {columns.map(({ algoliaAttributeName, render }) => (
                  <td key={algoliaAttributeName} className="cell-content">
                    {render ? render(row[algoliaAttributeName], row) : safeRender(row[algoliaAttributeName])}
                  </td>
                ))}
              </Row>
            );
          })}
        </Table>

        {selectedRows.length === 0 && <NoSelectedRows>There are no {entityPluralName} selected</NoSelectedRows>}
      </TableContainer>
      <PaginationSection>
        <Pagination current={currentPage} onChange={(page) => setCurrentPage(page)} total={selectedRows.length} />

        <div>
          Showing{' '}
          <ResultCount>
            {rangeStart} - {rangeEnd}{' '}
          </ResultCount>{' '}
          of <ResultCount>{selectedRows.length}</ResultCount> results
        </div>
      </PaginationSection>
    </Container>
  );
};

export { ContainerReview };

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  width: 100%;
  overflow: auto;
`;

const NoSelectedRows = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  font-size: 18px;
  margin-top: 24px;
  font-weight: 300;
`;

const ResultsHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 24px 16px;
  border-bottom: 1px solid lightgrey; // TODO: Fix colour
  font-weight: bold;
  color: #000000;
`;

const ResultCount = styled.span`
  font-weight: 600;
  color: #000000;
`;
