import React, { useContext } from 'react';
import { connectRefinementList, connectPagination } from 'react-instantsearch-core';

import { BatchAlgoliaPickerContext } from '../context';
const VirtualRefinement = connectRefinementList(() => null);

const PersistentFilterControls: React.FC = () => {
  const { persistentFilters } = useContext(BatchAlgoliaPickerContext);
  const filters = Object.keys(persistentFilters);

  return (
    <>
      {filters.map((attributeName) => {
        return (
          <VirtualRefinement
            key={`persistent-virtual-refinement-${attributeName}`}
            attribute={attributeName}
            defaultRefinement={[persistentFilters[attributeName]]}
          />
        );
      })}
    </>
  );
};

export const ConnectedPersistentFilters = connectPagination(PersistentFilterControls);
