import styled from 'styled-components';

import { ReactComponent as LoadingIcon } from '../svg-icons/loading.svg';

const Spinner: React.FC = () => {
  return <AnimatedLoadingIcon />;
};

const AnimatedLoadingIcon = styled(LoadingIcon)`
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  animation: rotation 1s infinite linear;
`;

export { Spinner };
