import React, { useContext } from 'react';
import styled from 'styled-components';

import { BatchAlgoliaPickerContext } from '../context';
import { Button } from './common/Button';
import { ContainerAlgolia } from './ContainerAlgolia';
import { ContainerReview } from './ContainerReview';
import { SummaryHeader } from './SummaryHeader';
import { ViewMode } from '../interfaces';
import { colours } from '../tokens';

type ContainerProps = {
  responsive?: boolean;
  tagline?: React.ReactNode;
};

const Container: React.FC<ContainerProps> = ({ responsive, tagline }) => {
  const { selectedRows, onSubmitText, setSelectedRows, onCancel, onSubmit, currentViewMode } = useContext(
    BatchAlgoliaPickerContext
  );

  return (
    <InnerContainer responsive={responsive}>
      <Header>
        <HeaderLeft>
          <SummaryHeader />
          {tagline}
        </HeaderLeft>
        <HeaderRight>
          {onCancel && (
            <Button
              onClick={() => {
                setSelectedRows([]);
                onCancel();
              }}
            >
              Cancel
            </Button>
          )}
          <Button
            onClick={() => {
              onSubmit(selectedRows);
              setSelectedRows([]);
            }}
            type="primary"
          >
            {onSubmitText}
          </Button>
        </HeaderRight>
      </Header>
      <Body>
        <SelectRecords isActive={currentViewMode === ViewMode.SELECT_RECORDS}>
          <ContainerAlgolia />
        </SelectRecords>
        <ViewRecords isActive={currentViewMode === ViewMode.REVIEW_SELECTED_RECORDS}>
          <ContainerReview />
        </ViewRecords>
      </Body>
    </InnerContainer>
  );
};

export { Container };

type InnerContainerProps = Pick<ContainerProps, 'responsive'>;

const InnerContainer = styled.div<InnerContainerProps>`
  width: 100%;
  height: 100%;
  border-radius: 3px;
  display: flex;
  flex-direction: column;

  ${({ responsive }) =>
    responsive &&
    `@media (max-width: 768px) {
    flex-direction: column-reverse;
  }`}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 18px;
  border-radius: 3px;
  background: ${colours.blue100};
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderRight = styled.div`
  display: flex;

  ${Button} {
    margin: 0 0 0 16px;
  }
`;

const Body = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
  min-height: min(790px, 80vh);
  background: #ffffff;
`;

type RecordsProps = {
  isActive: boolean;
};

const SelectRecords = styled.div<RecordsProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transition: all 0.4s ease-in-out;
  top: 0;
  ${({ isActive }) => (isActive ? 'right: 0;' : 'right: 100vw;')}
`;

const ViewRecords = styled.div<RecordsProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transition: all 0.4s ease-in-out;
  top: 0;
  ${({ isActive }) => (isActive ? 'left: 0;' : 'left: 100vw;')}
`;
