import { useContext, useEffect, useState } from 'react';
import { Checkbox, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { BatchAlgoliaPickerContext } from '../context';
import { AlgoliaEntry } from '../interfaces';
import { Spinner } from './common';

export const SelectAllCheckbox: React.FC = () => {
  const {
    algoliaIndexName,
    entitySingularName,
    entityPluralName,
    currentFilters,
    persistentFilters,
    hasSelectedAll,
    isSelectable,
    isSelectedInternal,
    searchClient,
    setHasSelectedAll,
    setSelectedRows
  } = useContext(BatchAlgoliaPickerContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tooltipMessage, setTooltipMessage] = useState<string | undefined>();

  useEffect(() => {
    // Immediately clear all tooltips if this component unmounts so it doesn't linger on"
    return () => setTooltipMessage(undefined);
  }, []);

  const getEntityName = (count: number) => (count === 1 ? entitySingularName : entityPluralName);

  const addAutoClosingTooltip = (message: string, timeout = 3000) => {
    setTooltipMessage(message);

    setTimeout(() => {
      setTooltipMessage(undefined);
    }, timeout);
  };

  const fetchAllRecordsInCurrentFilter = async () => {
    if (!searchClient) return [];

    const { search, ...otherFilters } = currentFilters;
    const searchInput = search ? search : '';

    const userFilters = Object.keys(otherFilters)
      .map((filterKey) => {
        const filterValue = otherFilters[filterKey];
        return filterValue ? `${filterKey}:${filterValue}` : undefined;
      })
      .filter((filterString: string | undefined): filterString is string => !!filterString);

    const persistedFilters = Object.keys(persistentFilters)
      .map((filterKey) => {
        const filterValue = persistentFilters[filterKey];
        return filterValue ? `${filterKey}:${filterValue}` : undefined;
      })
      .filter((filterString: string | undefined): filterString is string => !!filterString);

    const filters = [...userFilters, ...persistedFilters].join(' AND ');

    let results: Array<AlgoliaEntry> = [];

    await searchClient.initIndex(algoliaIndexName).browseObjects({
      query: searchInput,
      filters,
      batch: (batch) => (results = results.concat(batch))
    });

    return results;
  };

  const onSelectAll = async () => {
    try {
      const results = await fetchAllRecordsInCurrentFilter();
      const resultsToAdd = results.filter((result) => isSelectable(result) && !isSelectedInternal(result));
      const resultsAdded = resultsToAdd.length;

      const message =
        resultsAdded > 0
          ? `Selected ${resultsAdded} ${getEntityName(resultsAdded)}`
          : `All ${entityPluralName} already selected`;

      setSelectedRows((currentSelectedRows) => [...currentSelectedRows, ...resultsToAdd]);
      addAutoClosingTooltip(message);
      setHasSelectedAll(true);
    } catch (e) {
      addAutoClosingTooltip(`Unable to select all ${entityPluralName}`);
    }
  };

  const onUnselectAll = async () => {
    try {
      const results = await fetchAllRecordsInCurrentFilter();
      const resultsToRemove = results
        .filter((result) => isSelectable(result) && isSelectedInternal(result))
        .map((result) => result.objectID);

      const resultsRemoved = resultsToRemove.length;

      const message =
        resultsRemoved > 0
          ? `Unselected ${resultsRemoved} ${getEntityName(resultsRemoved)}`
          : `All ${entityPluralName} already unselected`;

      setSelectedRows((currentSelectedRows) =>
        currentSelectedRows.filter((row) => !resultsToRemove.includes(row.objectID))
      );

      addAutoClosingTooltip(message);
      setHasSelectedAll(false);
    } catch (e) {
      addAutoClosingTooltip(`Unable to unselect all ${entityPluralName}`);
    }
  };

  const onSelectAllChange = async (e: CheckboxChangeEvent) => {
    const isAllChecked = e.target.checked;

    setIsLoading(true);

    if (isAllChecked) await onSelectAll();
    if (!isAllChecked) await onUnselectAll();

    setIsLoading(false);
  };

  const checkboxTooltipMessage = tooltipMessage
    ? undefined
    : `${hasSelectedAll ? 'Unselect' : 'Select'} all ${entityPluralName}`;

  return (
    <div>
      <Tooltip placement="right" title={tooltipMessage} visible={!!tooltipMessage}>
        {isLoading ? (
          <Spinner />
        ) : (
          <Tooltip placement="right" title={checkboxTooltipMessage}>
            <Checkbox checked={hasSelectedAll} onChange={onSelectAllChange} />
          </Tooltip>
        )}
      </Tooltip>
    </div>
  );
};
