export const colours = {
  blue100: '#E8EFFF',
  blue700: '#427EFF',
  blue800: '#3068E1',
  darkGrey50: '#ADBAD0',
  darkGrey100: '#A2B0C9',
  darkGrey700: '#4A5D85',
  darkGrey900: '#243252',
  lightGrey300: '#F3F5FA',
  lightGrey500: '#E7ECF7',
  lightGrey700: '#D7E0F1',
  navy900: '#03001E'
};
