import { useContext } from 'react';
import styled from 'styled-components';
import { SearchBoxProvided, connectSearchBox } from 'react-instantsearch-core';
import { Input } from 'antd';
import { useDebouncedCallback } from 'use-debounce';

import { colours, breakpoints } from '../tokens';
import { BatchAlgoliaPickerContext } from '../context';
import { ReactComponent as SearchIcon } from './svg-icons/search.svg';
import { ReactComponent as CloseIcon } from './svg-icons/close-edge.svg';

type SearchBoxProps = Pick<SearchBoxProvided, 'refine'>;

const SearchBox: React.FC<SearchBoxProps> = ({ refine }) => {
  const debouncedRefine = useDebouncedCallback(refine, 400);

  const {
    entitySingularName,
    setCurrentFilters,
    currentFilters: { search }
  } = useContext(BatchAlgoliaPickerContext);

  return (
    <StyledInput
      placeholder={`Filter by ${entitySingularName}`}
      value={search}
      onChange={(e) => {
        const search = e.target.value;
        setCurrentFilters({ search });
        debouncedRefine(search);
      }}
      suffix={
        <SearchInputIcon
          isEmpty={!search || search.length === 0}
          clearSearch={() => {
            setCurrentFilters({ search: '' });
            refine();
          }}
        />
      }
    />
  );
};

interface SearchInputIconProps {
  isEmpty: boolean;
  clearSearch: () => void;
}

const SearchInputIcon: React.FC<SearchInputIconProps> = ({ isEmpty, clearSearch }) => {
  if (isEmpty) return <SearchIcon />;
  return <StyledCloseIcon onClick={clearSearch} />;
};

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

const StyledInput = styled(Input)`
  input {
    margin-right: 6px;
    &::placeholder {
      border-color: ${colours.darkGrey100};
      color: ${colours.darkGrey700};
      opacity: 1;
    }
    border-color: ${colours.darkGrey100};
    @media (min-width: ${breakpoints.lg}) {
      width: 300px;
    }
  }
`;

export const ConnectedSearchBox = connectSearchBox(({ refine }) => <SearchBox refine={refine} />);
