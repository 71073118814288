export const safeRender = (attributeValue: unknown): React.ReactNode => {
  if (attributeValue === undefined) return null;
  if (attributeValue === null) return null;

  if (typeof attributeValue === 'number') return attributeValue;
  if (typeof attributeValue === 'string') return attributeValue;
  if (typeof attributeValue === 'boolean') return attributeValue;

  if (Array.isArray(attributeValue)) return attributeValue;

  console.error('Unable to safe render:', attributeValue);
  return null;
};
