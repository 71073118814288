import styled from 'styled-components';
import { Button as AntButton } from 'antd';
import { colours } from '../../tokens';

const Button = styled(AntButton)`
  &&& {
    height: auto;
    font-size: 14px;
    line-height: 1.75;
    padding: 5px 17px;

    &:hover:not([disabled]) {
      background-color: ${colours.lightGrey500};
      border-color: ${colours.lightGrey500};
    }

    &[disabled],
    &.ant-btn-loading {
      opacity: 0.6;
    }

    background-color: ${colours.lightGrey300};
    border-color: ${colours.lightGrey300};
    color: ${colours.darkGrey900};

    &:hover:not([disabled]) {
      background-color: ${colours.lightGrey500};
      border-color: ${colours.lightGrey300};
    }

    ${({ type }) =>
      type === 'primary' &&
      `
      background-color: ${colours.blue700};
      border-color: ${colours.blue700};
      color: #ffffff;

      &:hover:not([disabled]) {
        background-color: ${colours.blue800};
        border-color: ${colours.blue800};
      }`}
  }
`;

export { Button };
