import { Tooltip } from 'antd';
import { useContext } from 'react';
import styled from 'styled-components';

import { BatchAlgoliaPickerContext } from '../context';
import { ViewMode } from '../interfaces';
import { ReactComponent as FilterIcon } from './svg-icons/filter.svg';
import { ReactComponent as BackArrowIcon } from './svg-icons/arrow-back.svg';
import { colours } from '../tokens';

export const SummaryHeader: React.FC = () => {
  const { selectedRows, entityPluralName, currentViewMode, setCurrentViewMode } = useContext(BatchAlgoliaPickerContext);

  if (currentViewMode === ViewMode.REVIEW_SELECTED_RECORDS) {
    return (
      <ActionPill onClick={() => setCurrentViewMode(ViewMode.SELECT_RECORDS)}>
        <StyledBackArrowIcon />
        <span>Back to all {entityPluralName}</span>
      </ActionPill>
    );
  }

  if (!selectedRows.length) {
    return null;
  }

  return (
    <Tooltip placement="right" title={`Filter selected ${entityPluralName}`}>
      <ActionPill onClick={() => setCurrentViewMode(ViewMode.REVIEW_SELECTED_RECORDS)}>
        <span>{selectedRows.length} selected</span>
        <StyledFilterIcon />
      </ActionPill>
    </Tooltip>
  );
};

const ActionPill = styled.div`
  align-items: center;
  background-color: ${colours.blue700};
  border-radius: 16px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  justify-content: center;
  line-height: 20px;
  margin-right: 8px;
  padding: 2px 12px;
`;

const StyledBackArrowIcon = styled(BackArrowIcon)`
  margin-right: 4px;
`;

const StyledFilterIcon = styled(FilterIcon)`
  margin-left: 4px;
`;
