import { useContext } from 'react';
import { CurrentRefinementsProvided, connectCurrentRefinements } from 'react-instantsearch-core';
import { Button } from 'antd';

import { BatchAlgoliaPickerContext } from '../context';

type ClearResultsProps = Pick<CurrentRefinementsProvided, 'refine' | 'items'>;

const ClearResults: React.FC<ClearResultsProps> = ({ refine, items }) => {
  const { setCurrentFilters, currentFilters } = useContext(BatchAlgoliaPickerContext);

  const filterKeys = Object.keys(currentFilters);
  const hasFiltersApplied = filterKeys.filter((key) => currentFilters[key] !== '').length > 0;

  if (!hasFiltersApplied) {
    return null;
  }

  return (
    <Button
      onClick={() => {
        setCurrentFilters();
        refine(items);
      }}
    >
      Clear filters
    </Button>
  );
};

export const ConnectedClearResults = connectCurrentRefinements(({ refine, items }) => (
  <ClearResults refine={refine} items={items} />
));
