import { AppliedFilters } from '../interfaces';

/**
 * @param requestedFilters AppliedFilters
 * @param persistentFilters AppliedFilters
 * @returns AppliedFilters
 *
 * This method ensures that the user can only apply filters that are not set by the persistent filters.
 * If the persistent filters have already set the value for an attribute, then we will omit it from requestedFilters
 */
export const safeFilters = (requestedFilters: AppliedFilters, persistentFilters: AppliedFilters): AppliedFilters => {
  const requestedFilterKeys = Object.keys(requestedFilters);
  const persistentFiltersKeys = Object.keys(persistentFilters);

  // Only allow a filter if it's not already in persistentFilters:
  const allowedFilterKeys = requestedFilterKeys.filter((filterKey) => {
    const isAllowed = !persistentFiltersKeys.includes(filterKey);

    if (!isAllowed) {
      console.warn(`ReactAlgoliaTable: ${filterKey} skipped since it is found in persistent filters`);
    }

    return isAllowed;
  });

  return allowedFilterKeys.reduce((allowedFilters: AppliedFilters, filterKey) => {
    allowedFilters[filterKey] = requestedFilters[filterKey];
    return allowedFilters;
  }, {});
};
