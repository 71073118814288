import styled from 'styled-components';

import { colours } from '../../tokens';

export const Table = styled.table`
  color: ${colours.navy900};
  background: #ffffff;
  font-size: 14px;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;

  thead th,
  tfoot th {
    color: ${colours.navy900};
    background: rgba(0, 0, 0, 0.1);
  }

  th,
  td {
    padding: 12px 16px;
  }

  .cell-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .col-checkbox {
    width: 3%;
    min-width: 30px;
  }
`;

type ColumnProps = { columnWidth?: string };

export const Column = styled.col<ColumnProps>`
  ${({ columnWidth }) => columnWidth && `width: ${columnWidth}`};
`;

export const HeaderRow = styled.tr`
  border-bottom: 1px solid ${colours.lightGrey700};
`;

type RowProps = { checked: boolean; disabled?: boolean };

export const Row = styled.tr<RowProps>`
  border-bottom: 1px solid ${colours.lightGrey700};
  cursor: pointer;

  ${({ checked, disabled }) => checked && !disabled && 'background-color: rgba(232, 239, 255, 0.5);'}
  ${({ disabled }) =>
    disabled &&
    `
      background-color: ${colours.lightGrey300};
      cursor: not-allowed;
      opacity: 0.5;
    `}

  &:hover {
    background-color: rgba(232, 239, 255, 0.5);
    ${({ disabled }) =>
      disabled &&
      `
        background-color: ${colours.lightGrey300};
        cursor: not-allowed;
      `}
  }
`;
