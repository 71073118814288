import { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { connectStateResults, StateResultsProvided } from 'react-instantsearch-core';

import { BatchAlgoliaPickerContext } from '../context';
import { MessageContainer } from './MessageContainer';

const StateResults: React.FC<StateResultsProvided> = ({ searchResults, error, searching, isSearchStalled }) => {
  const { setTotalRecordsCount } = useContext(BatchAlgoliaPickerContext);
  const hasResults = searchResults && searchResults.nbHits !== 0;
  const nbHits = searchResults && searchResults.nbHits;
  const nbPages = searchResults && searchResults.nbPages;
  const page = searchResults && searchResults.page;
  const hitsPerPage = searchResults && searchResults.hitsPerPage;
  const totalCount = hasResults ? nbHits : 0;
  const startCount = page * hitsPerPage + 1;
  const isLastPage = page + 1 === nbPages;
  const endCount = isLastPage ? nbHits : (page + 1) * hitsPerPage;

  useEffect(() => {
    setTotalRecordsCount(totalCount);
  }, [totalCount, setTotalRecordsCount]);

  if (searching) {
    return (
      <MessageContainer>
        <span>Searching...</span>
      </MessageContainer>
    );
  }

  if (isSearchStalled) {
    return <MessageContainer>Searching is stalled</MessageContainer>;
  }

  if (error && error.message) {
    return (
      <MessageContainer>
        <strong>Error: </strong>
        <span>{[error.message].join(', ')}</span>
      </MessageContainer>
    );
  }

  if (!hasResults) {
    return null;
  }

  return (
    <MessageContainer>
      Showing{' '}
      <ResultCount>
        {startCount} - {endCount}{' '}
      </ResultCount>{' '}
      of <ResultCount>{totalCount}</ResultCount> results
    </MessageContainer>
  );
};

export const ConnectedStateResults = connectStateResults(StateResults);

const ResultCount = styled.span`
  font-weight: 600;
`;
