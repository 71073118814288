import styled from 'styled-components';

type MessageContainerProps = {
  children: React.ReactNode;
};

export const MessageContainer: React.FC<MessageContainerProps> = ({ children }) => {
  return (
    <Container>
      {children}
    </Container>
  );
};

const Container = styled.div<{ isError?: boolean }>`
  line-height: 17px;
  color: ${({ isError }) => (isError ? 'red' : '#03001E')};

  > p {
    margin: 4px 0;
    padding: 0;
  }
`;
