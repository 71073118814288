import { ReactElement, useCallback, useRef, useState } from 'react';
import algoliasearch, { SearchClient } from 'algoliasearch';

import { BatchAlgoliaPickerContext, IBatchAlgoliaPickerContext } from '../context';
import { Column, AlgoliaEntry, AppliedFilters, Filter, ViewMode } from '../interfaces';
import { Container } from '../ui';
import { safeFilters } from '../utils';

export interface BatchAlgoliaPickerProps<T> {
  algoliaAPIKey: string;
  algoliaAppId: string;
  algoliaIndexName: string;
  columns: Array<Column<T>>;
  persistentFilters?: AppliedFilters;
  entityPluralName?: string;
  entitySingularName?: string;
  filters?: Array<Filter>;
  preselectedRows?: Array<T>;
  initialViewMode?: ViewMode;
  isSelectable?: (row: T) => boolean;
  isSelected?: (row: T) => boolean;
  onCancel?: () => void;
  onSubmit: (entries: Array<T>) => void;
  onSubmitText?: string;
  responsive?: boolean;
  tagline?: React.ReactNode;
}

export const BatchAlgoliaPicker = <T extends AlgoliaEntry>(props: BatchAlgoliaPickerProps<T>): ReactElement => {
  const {
    algoliaAPIKey,
    algoliaAppId,
    algoliaIndexName,
    columns,
    preselectedRows = [],
    persistentFilters = {},
    entityPluralName = 'records',
    entitySingularName = 'record',
    filters = [],
    initialViewMode = ViewMode.SELECT_RECORDS,
    isSelectable = () => true,
    isSelected: isSelectedExternal = () => false,
    onCancel,
    onSubmit,
    onSubmitText = 'Save',
    responsive = true,
    tagline
  } = props;

  const [selectedRows, setSelectedRows] = useState<Array<T>>(preselectedRows);
  const [currentViewMode, setCurrentViewMode] = useState<ViewMode>(initialViewMode);
  const [hasSelectedAll, setHasSelectedAll] = useState<boolean>(false);
  const [currentFilters, setFilters] = useState<AppliedFilters>({});
  const [totalRecordsCount, setTotalRecordsCount] = useState<number>(0);

  const createAlgoliaSearch = useCallback(() => {
    return algoliasearch(algoliaAppId, algoliaAPIKey);
  }, [algoliaAppId, algoliaAPIKey]);

  const searchClientRef = useRef<SearchClient>(createAlgoliaSearch());

  /**
   * [Utility Function]: isSelectedInternal is to determine if the row is selected in the our internal state.
   * This is mainly used to determine whether the checkbox is ticked or not for selectable rows.
   */
  const isSelectedInternal = (row: T) => !!selectedRows.find((r) => r.objectID === row.objectID);

  /**
   * [Utility Function]: Toggle the row selection. Add/Remove from our internal selectedRows state accordingly
   */
  const onRowSelect = (r: T) => {
    setSelectedRows((rows) =>
      isSelectedInternal(r) || isSelectedExternal(r) ? rows.filter((row) => row.objectID !== r.objectID) : [...rows, r]
    );
  };

  /**
   * [Utility Function]: Handle the partial updates vs complete reset for when the filters are updated.
   */
  const setCurrentFilters = (partialFiltersOrReset?: AppliedFilters) => {
    if (partialFiltersOrReset) {
      // A partial update to the filter state is been requested:
      const newPartialFilters = safeFilters(partialFiltersOrReset, persistentFilters);
      setFilters((currentState) => ({ ...currentState, ...newPartialFilters }));
    } else {
      // A complete reset is being requested:
      setFilters({});
    }

    setHasSelectedAll(false);
  };

  /**
   * [useContext Provider]: Initialize the internal state to manage all the various things we have to do with the dataset.
   */
  const BatchAlgoliaPickerContextValue: IBatchAlgoliaPickerContext<T> = {
    algoliaIndexName,
    columns,
    currentFilters,
    currentViewMode,
    persistentFilters,
    entityPluralName,
    entitySingularName,
    filters,
    hasSelectedAll,
    isSelectable,
    isSelectedExternal,
    isSelectedInternal,
    onCancel,
    onRowSelect,
    onSubmit,
    onSubmitText,
    recordsPerPage: 10,
    searchClient: searchClientRef.current,
    selectedRows,
    setCurrentFilters,
    setCurrentViewMode,
    setHasSelectedAll,
    setSelectedRows,
    setTotalRecordsCount,
    totalRecordsCount
  };

  if (!algoliaAPIKey || !algoliaAppId || !algoliaIndexName) {
    const errors: Array<string> = [];

    if (!algoliaAppId) errors.push('Missing Algolia App ID');
    if (!algoliaAPIKey) errors.push('Missing Algolia API Key');
    if (!algoliaIndexName) errors.push('Missing Algolia Index Name');

    return <p>An error has occurred: {errors.join(', ')}</p>;
  }

  return (
    // TODO: Fix any cast!
    <BatchAlgoliaPickerContext.Provider value={BatchAlgoliaPickerContextValue as any}>
      <Container responsive={responsive} tagline={tagline} />
    </BatchAlgoliaPickerContext.Provider>
  );
};
