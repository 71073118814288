import { useContext } from 'react';
import { Configure, connectPagination } from 'react-instantsearch-dom';
import { Pagination as AntDPagination } from 'antd';

import { BatchAlgoliaPickerContext } from '../context';
import { ConnectedStateResults } from './ConnectedStateResults';
import { PaginationSection } from './common';

type PaginationProps = {
  currentRefinement: number;
  refine: (page: number) => void;
  nbPages: number;
};

const Pagination: React.FC<PaginationProps> = ({ currentRefinement, nbPages, refine }) => {
  const { recordsPerPage } = useContext(BatchAlgoliaPickerContext);

  return (
    <PaginationSection>
      <AntDPagination current={currentRefinement} onChange={(page) => refine(page)} total={nbPages * recordsPerPage} />
      <Configure hitsPerPage={recordsPerPage} />
      <ConnectedStateResults />
    </PaginationSection>
  );
};

export const ConnectedPagination = connectPagination(Pagination);
